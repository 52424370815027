import React, { useEffect, useState } from "react";
import ID from "../../images/ID.png";
import "./TravelInvalidID.css";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Oval } from "react-loader-spinner";


const TravelInvalidID = () => {
  const navigate = useNavigate();
  const { serviceCode } = useParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);
  const handleCancel = () => {
    navigate(`/redirect/${serviceCode}/error`);
  };
  return (
    <div className="TravelInvalidID">
    {loading ? (
      <div className="jsonForm-loader">
        <Oval
          visible={true}
          height={40}
          width={40}
          color="#4fa94d"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    ) : (
      <>
        <div>
          <img src={ID} alt="Identification" />
        </div>
        <p className="header">
          <FormattedMessage id="component.travel.invalid" />
        </p>
        <p className="body">
          <FormattedMessage id="component.travel.invalidID.message" />
        </p>
        <button className="button-modal-green btn" onClick={handleCancel}>
          <FormattedMessage id="component.button.back" />
        </button>
      </>
    )}
  </div>
  );
};

export default TravelInvalidID;
