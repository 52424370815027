import React from "react";

const CountryDecoder = (continentCode) => {
    const decodeContinent = (c) => {
        switch (c) {
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
            return 'North America';
          case '8':
          case '9':
            return 'South America';
          case 'J':
          case 'K':
          case 'L':
          case 'M':
          case 'N':
          case 'P':
          case 'R':
            return 'Asia';
          case 'S':
          case 'T':
          case 'U':
          case 'V':
          case 'W':
          case 'X':
          case 'Y':
          case 'Z':
            return 'Europe';
          case '6':
          case '7':
            return 'Oceania';
          case 'A':
          case 'B':
          case 'C':
          case 'D':
          case 'E':
          case 'F':
          case 'G':
          case 'H':
            return 'Africa';
          default:
            throw new Error(`Invalid continent code: ${c}`);
        }
      };
    
      return <div>{decodeContinent(continentCode)}</div>;
    };

  export default CountryDecoder;
  