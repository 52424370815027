import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./constant/Navbar/Navbar";
import Home from "./constant/Home/Home";
import Main from "./constant/Main/Main";
import Checkout from "./constant/Checkout/Checkout";
import Onboarding from "./starAssurance/Onboarding/Onboarding";
import { VehicleDetails } from "./starAssurance/MotorInsurance/VehicleDetails";
import Premium from "./starAssurance/MotorInsurance/Premium/Premium";
import { PayPremium } from "./starAssurance/MotorInsurance/PayPremium/PayPremium";
import PolicyDetails from "./starAssurance/MotorInsurance/PolicyDetails/PolicyDetails";
import Success from "./starAssurance/MotorInsurance/PayPremium/Success";
import TravelDetails from "./starAssurance/TravelInsurance/TravelDetails/TravelDetails";
import TravelPremium from "./starAssurance/TravelInsurance/TravelPremium/TravelPremium";
import TravelPolicyDetails from "./starAssurance/TravelInsurance/TravelPolicyDetails/TravelPolicyDetails";
import TravelPayPremium from "./starAssurance/TravelInsurance/TravelPayPremium/TravelPayPremium";
import { IntlProvider } from "react-intl";
import messages_en from "./utils/lang/locales/en_US.json";
import messages_fr from "./utils/lang/locales/fr_FR.json";
import LanguageSelector from "./constant/LanguageSelector/LanguageSelector";
import { useState } from "react";
import ErrorBoundary from "./utils/ErrorBoundary/ErrorBoundary";
import TravelComingSoon from "./starAssurance/TravelComingSoon/TravelComingSoon";
import JsonForm from "./circles/JsonForms";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VinDecoder from "./starAssurance/MotorInsurance/VinDecoder/VinDecoder";

const App = () => {
  const [locale, setLocale] = useState("en");

  const messages = {
    en: messages_en,
    fr: messages_fr,
  };
  const handleSelectLanguage = (selectedLocale) => {
    setLocale(selectedLocale);
  };
  return (
    <div className="App_Layer">
      <ErrorBoundary>
        <IntlProvider
          locale={locale}
          messages={messages[locale]}
        >
          <BrowserRouter>
              <Navbar/>
              <LanguageSelector onSelectLanguage={handleSelectLanguage}/>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path=":serviceCode" element={<Main/>} />
                <Route path="/checkout/:invoiceNum" element={<Checkout/>} />


                <Route path="/vehicle/:serviceCode/:customer" element={<VinDecoder/>} />
                <Route path="/vehicle/:serviceCode/policyDetails" element={<PolicyDetails/>} />
                <Route path="/vehicle/:serviceCode/vehicleDetails" element={<VehicleDetails/>} />
                <Route path="/vehicle/:serviceCode/premium" element={<Premium/>} />
                <Route path="/vehicle/:serviceCode/underwriting" element={<PayPremium/>} />


                <Route path="/redirect/:serviceCode/success" element={<Success/>} />
                <Route path="/redirect/:serviceCode/error" element={<Success/>} />


                <Route path="/travel/:serviceCode/travelDetails/:customer" element={<TravelDetails/>} />
                <Route path="/travel/:serviceCode/travelPremium" element={<TravelPremium/>} />
                <Route path="/travel/:serviceCode/travelPolicyDetails" element={<TravelPolicyDetails/>} />
                <Route path="/travel/:serviceCode/travelPayPremium" element={<TravelPayPremium/>} />


                <Route path="/circles/:circleCode/:customer" element={<JsonForm/>} />
                <Route path="/circles/:circleCode/success" element={<Success/>} />
                <Route path="/circles/:circleCode/error" element={<Success/>} />
              </Routes>
            </BrowserRouter>
        </IntlProvider>
      </ErrorBoundary>
      <ToastContainer />
    </div>
  );
};

export default App;
