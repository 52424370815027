import React from "react";

const EngineSizeDecoder = ({ code }) => {
  const engineSizeMap = {
    A: "Gasoline, 3 or 4 cylinders",
    B: "Gasoline, 3 or 4 cylinders",
    C: "Gasoline, 4 or 6 cylinders",
    D: "Gasoline, 4 or 6 cylinders",
    E: "Gasoline, 4 or 6 cylinders",
    F: "Gasoline, 4 or 6 cylinders",
    G: "Gasoline, 6 or 8 cylinders",
    H: "Gasoline, 6 or 8 cylinders",
    J: "Gasoline, 6 or 8 cylinders",
    K: "Gasoline, 6 or 8 cylinders",
    L: "Gasoline, 6 or 8 cylinders",
    M: "Gasoline, 8 cylinders",
    N: "Gasoline, 8 cylinders",
    P: "Gasoline, 8 cylinders",
    R: "Gasoline, 8 cylinders",
    S: "Diesel, 4 or 6 cylinders",
    T: "Diesel, 4 or 6 cylinders",
    U: "Diesel, 4 or 6 cylinders",
    V: "Diesel, 4 or 6 cylinders",
    W: "Diesel, 4 or 6 cylinders",
    X: "Diesel, 6 or 8 cylinders",
    Y: "Diesel, 6 or 8 cylinders",
    Z: "Diesel, 6 or 8 cylinders",
    "1": "Electric",
    "2": "Hybrid",
    "3": "Hybrid",
    "4": "Hybrid",
    "5": "Hybrid",
    "6": "Hybrid",
    "7": "Hybrid",
    "8": "Hybrid",
    "9": "Hybrid",
  };

  if (engineSizeMap.hasOwnProperty(code)) {
    return <div>{engineSizeMap[code]}</div>;
  } else {
    throw new Error("Invalid engine size code");
  }
};

export default EngineSizeDecoder;
