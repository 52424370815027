import React, { useEffect, useState } from "react";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import "./TravelDetails.css";
import { Oval } from "react-loader-spinner";
import { RiArrowDropDownLine, RiCalendar2Line } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import TravelInvalidID from "../../TravelInvalidID/TravelInvalidID";

const TravelDetails = () => {
  const { serviceCode } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [customerDetails, setCustomerDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    dateOfBirth: "",
    identificationNumber: "",
    identificationType: "",
    gender: "",
  });

  const [destination, setDestination] = useState([]);

  const [destinationCountryCode, setDestinationCountryCode] = useState("");
  const [destinationCountryName, setDestinationCountryName] = useState("");
  const [coverage, setCoverage] = useState("");
  const [travelDate, setTravelDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [destinationAddress, setDestinationAddress] = useState("");
  const [destinationNumber, setDestinationNumber] = useState("");

  const [destinationCountryCodeError, setDestinationCountryCodeError] =
    useState("");
  const [coverageError, setCoverageError] = useState("");
  const [travelDateError, setTravelDateError] = useState("");
  const [returnDateError, setReturnDateError] = useState("");
  const [destinationAddressError, setDestinationAddressError] = useState("");
  const [destinationNumberError, setDestinationNumberError] = useState("");

  useEffect(() => {
    setLoading(true);
    const urlParams = new URLSearchParams(window.location.search);
    setCustomerDetails({
      firstName: urlParams.get("firstName"),
      lastName: urlParams.get("lastName"),
      phoneNumber: urlParams.get("PhoneNumber"),
      email: urlParams.get("email"),
      dateOfBirth: urlParams.get("dateOfBirth"),
      identificationNumber: urlParams.get("identificationNumber"),
      identificationType: urlParams.get("identificationType"),
      gender: urlParams.get("gender"),
    });
    setLoading(false);
  }, [serviceCode]);

  const identificationNumber=customerDetails?.identificationNumber

  useEffect(() => {
    localStorage.setItem("customerDetails", JSON.stringify(customerDetails));
  }, [customerDetails]);

  useEffect(() => {
    const fetchData = async () => {
      let uuid = uuidv4();
      setLoading(true);
      try {
        setLoading(true);

        const countryResponse = await axios.post("/star", {
          requestId: uuid,
          serviceCode: serviceCode,
          method: "country",
          async: true,
          params: {},
          url: "/switching/process",
        });

        setDestination(countryResponse?.data?.result);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const metaTag = document.createElement("meta");
    metaTag.name = "viewport";
    metaTag.content =
      "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no";

    document.head.appendChild(metaTag);

    return () => {
      document.head.removeChild(metaTag);
    };
  }, []);

  useEffect(() => {
    const storedTravelDetails = localStorage.getItem("travelDetails");
    if (storedTravelDetails) {
      const parsedDetails = JSON.parse(storedTravelDetails);
      setDestinationCountryCode(parsedDetails.destinationCountryCode);
      setDestinationCountryName(parsedDetails.destinationCountryName);
      setCoverage(parsedDetails.coverage);
      setTravelDate(parsedDetails.travelDate);
      setReturnDate(parsedDetails.returnDate);
      setDestinationAddress(parsedDetails.destinationAddress);
      setDestinationNumber(parsedDetails.destinationNumber);
    }
  }, []);

  const calculateAge = (dateOfBirth) => {
    const dob = new Date(dateOfBirth);
    const now = new Date();
    let age = now.getFullYear() - dob.getFullYear();
    const monthDiff = now.getMonth() - dob.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
      age--;
    }
    return age;
  };

  const generatePremium = () => {
    let hasError = false;

    if (destinationCountryCode === "") {
      setDestinationCountryCodeError(
        <FormattedMessage id="component.travel.destinationCountryCodeError" />
      );
      hasError = true;
    } else {
      setDestinationCountryCodeError("");
    }

    if (coverage === "") {
      setCoverageError(
        <FormattedMessage id="component.travel.coverageError" />
      );
      hasError = true;
    } else {
      setCoverageError("");
    }

    if (travelDate === "") {
      setTravelDateError(
        <FormattedMessage id="component.travel.travelDateError" />
      );
      hasError = true;
    } else {
      setTravelDateError("");
    }

    if (returnDate === "") {
      setReturnDateError(
        <FormattedMessage id="component.travel.returnDateError" />
      );
      hasError = true;
    } else {
      setReturnDateError("");
    }

    if (destinationAddress === "") {
      setDestinationAddressError(
        <FormattedMessage id="component.travel.destinationAddressError" />
      );
      hasError = true;
    } else {
      setDestinationAddressError("");
    }
    if (destinationNumber === "") {
      setDestinationNumberError(
        <FormattedMessage id="component.travel.destinationNumberError" />
      );
      hasError = true;
    } else {
      setDestinationNumberError("");
    }

    if (!hasError) {
      const start = new Date(travelDate);
      const end = new Date(returnDate);
      const timeDiff = Math.abs(end.getTime() - start.getTime());
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      const age = calculateAge(customerDetails.dateOfBirth);

      const travelDetails = {
        destinationCountryCode: destinationCountryCode,
        destinationCountryName: destinationCountryName,
        coverage: coverage,
        travelDate: travelDate,
        returnDate: returnDate,
        destinationAddress: destinationAddress,
        destinationNumber: destinationNumber,
        days: days,
        age: age,
      };
      localStorage.setItem("travelDetails", JSON.stringify(travelDetails));
      navigate(`/travel/${serviceCode}/travelPremium`);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };
  const preventNonNumericInput = (event) => {
    const key = event.key;

    const allowedKeys = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "ArrowUp",
      "ArrowDown",
      "+",
    ];

    const isNumeric = !isNaN(parseInt(key));

    if (!allowedKeys.includes(key) && !isNumeric) {
      event.preventDefault();
    }
  };

  return (
    <>
      {loading ? (
        <div className="jsonForm-loader">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : identificationNumber === "" ? (
        <TravelInvalidID />
      ) : (
        <div className="TravelInsurance">
          <div className="wizard">
            <div className="wizardComponent">
              <div className="TravelDetails" style={{ color: "#000" }}>
                <FormattedMessage id="component.travel.travelDetails" />
              </div>
              <div className="CustomerDetails">
                <FormattedMessage id="component.travel.policyDetails" />
              </div>
              <div className="Premium2">
                <FormattedMessage id="component.getQuote" />
              </div>
              <div className="Payment">
                <FormattedMessage id="component.payment" />
              </div>
              <div className="Rectangle1"></div>
              <div className="Rectangle2"></div>
              <div className="Rectangle3"></div>
              <div className="Ellipse">
                <div className="EllipsePremium"></div>
                <div className="EllipseCustomerDetails"></div>
                <div className="EllipsePayment"></div>
                <div
                  className="EllipseTravelDetails"
                  style={{ background: "#40AD52" }}
                ></div>
              </div>
            </div>
          </div>

          <div className="TravelInsuranceTitle">
            <h3>
              <FormattedMessage id="component.travel.travelDetails" />
            </h3>
          </div>

          <form className="form">
            <div className="formPosition">
              <label>
                <FormattedMessage id="component.travel.destinationCountry" />
              </label>
              <div className="selectContainer">
                <select
                  name=""
                  className="formStyle"
                  value={destinationCountryCode}
                  onChange={(e) => {
                    setDestinationCountryCode(e.target.value);
                    const selectedRisk = destination.find(
                      (item) => item.CODE === e.target.value
                    );
                    setDestinationCountryName(selectedRisk?.NAME);
                  }}
                >
                  <option value="">
                    <FormattedMessage id="component.travel.destinationCountry.option" />
                  </option>
                  {destination?.map((item, index) => (
                    <option
                      value={item.CODE}
                      key={index}
                      style={{ padding: 9 }}
                    >
                      {item.NAME}
                    </option>
                  ))}
                </select>
                <RiArrowDropDownLine className="selectIcon" />
              </div>
              <div className="MotorInsuranceerror">
                {destinationCountryCodeError && (
                  <span className="shake shake1">
                    {destinationCountryCodeError}
                  </span>
                )}
              </div>
            </div>

            <div className="formPosition">
              <label>
                <FormattedMessage id="component.travel.coverage" />
              </label>
              <div className="selectContainer">
                <select
                  name=""
                  className="formStyle"
                  value={coverage}
                  onChange={(e) => {
                    setCoverage(e.target.value);
                  }}
                >
                  <option value="">
                    <FormattedMessage id="component.travel.coverage.option" />
                  </option>
                  <option value="S">Shengen-Normal</option>
                  <option value="N">Non-Schengen</option>
                  <option value="G">Schengen-Gremany</option>
                </select>
                <RiArrowDropDownLine className="selectIcon" />
              </div>
              <div className="MotorInsuranceerror">
                {coverageError && (
                  <span className="shake shake1">{coverageError}</span>
                )}
              </div>
            </div>

            <div className="datePickersPosition">
              <div className="formPosition">
                <label>
                  <FormattedMessage id="component.travel.travelDate" />
                </label>
                <div className="datePickerContainer">
                  <input
                    type="date"
                    name=""
                    className="datePickersStyle"
                    value={travelDate}
                    onChange={(e) => {
                      setTravelDate(e.target.value);
                    }}
                  />
                  <RiCalendar2Line className="datePickerIcon" />
                </div>
                <div className="MotorInsuranceerror">
                  {travelDateError && (
                    <span className="shake shake1">{travelDateError}</span>
                  )}
                </div>
              </div>
              <div className="formPosition">
                <label>
                  <FormattedMessage id="component.travel.returnDate" />
                </label>
                <div className="datePickerContainer">
                  <input
                    type="date"
                    name=""
                    className="datePickersStyle"
                    value={returnDate}
                    onChange={(e) => setReturnDate(e.target.value)}
                  />
                  <RiCalendar2Line className="datePickerIcon" />
                </div>
                <div className="MotorInsuranceerror">
                  {returnDateError && (
                    <span className="shake shake1">{returnDateError}</span>
                  )}
                </div>
              </div>
            </div>

            <div className="formPosition">
              <label>
                <FormattedMessage id="component.travel.destinationAddress" />
              </label>
              <div className="selectContainer">
                <textarea
                  name=""
                  className="textareaStyle"
                  rows={4}
                  cols={50}
                  value={destinationAddress}
                  onChange={(e) => {
                    const value = e.target.value.slice(0, 120);
                    setDestinationAddress(value);
                  }}
                />
              </div>
              <div className="MotorInsuranceerror">
                {destinationAddressError && (
                  <span className="shake shake1">
                    {destinationAddressError}
                  </span>
                )}
              </div>
            </div>
            <div className="formPosition">
              <label>
                <FormattedMessage id="component.travel.destinationNumber" />
              </label>
              <div className="selectContainer">
                <input
                  type="tel"
                  name=""
                  className="formStyle"
                  placeholder="eg. +233201234567"
                  value={destinationNumber}
                  onChange={(e) => {
                    const value = e.target.value.slice(0, 13);
                    setDestinationNumber(value);
                  }}
                  onKeyDown={(e) => preventNonNumericInput(e)}
                />
              </div>
              <div className="MotorInsuranceerror">
                {destinationNumberError && (
                  <span className="shake shake1">{destinationNumberError}</span>
                )}
              </div>
            </div>
          </form>

          <div className="button">
            <div className="button-double">
              <button className="button-gray" onClick={handleBackClick}>
                <FormattedMessage id="component.button.back" />
              </button>
              <button className="button-green" onClick={generatePremium}>
                <FormattedMessage id="component.button.next" />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TravelDetails;
