import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import axios from "axios";
import "./VinDecoder.css";
import CountryDecoder from "./CountryDecoder";
import ModelYearDecoder from "./ModelYearDecoder";
import EngineSizeDecoder from "./EngineSizeDecoder";
import { FormattedMessage } from "react-intl";
import { Oval} from "react-loader-spinner";
import { useNavigate, useParams } from "react-router-dom";
import vin from "../../../images/vin.png";

const VinDecoder = () => {
  const navigate = useNavigate();
  const { serviceCode } = useParams();
  const [vinNumber, setVinNumber] = useState("");
  const [vinNumberError, setVinNumberError] = useState(false);
  const [vinNumberError2, setVinNumberError2] = useState(false);
  const [decodedDetails, setDecodedDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const [customerDetails, setCustomerDetails] = useState({
    firstName: "",
    lastName: "",
    phoneNumber: "",
    email: "",
    dateOfBirth: "",
    identificationNumber: "",
    identificationType: "",
    gender: "",
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setCustomerDetails({
      firstName: urlParams.get("firstName"),
      lastName: urlParams.get("lastName"),
      phoneNumber: urlParams.get("PhoneNumber"),
      email: urlParams.get("email"),
      dateOfBirth: urlParams.get("dateOfBirth"),
      identificationNumber: urlParams.get("identificationNumber"),
      identificationType: urlParams.get("identificationType"),
      gender: urlParams.get("gender"),
    });
    setCode(serviceCode);
  }, [serviceCode]);

  useEffect(() => {
    const storedVinNumberDetail = localStorage.getItem("vinNumberDetail");
    if (storedVinNumberDetail) {
      const parsedDetails = JSON.parse(storedVinNumberDetail);
      setVinNumber(parsedDetails?.vinNumber);
    }
  }, []);

  const handleVinNumber = (e) => {
    setVinNumber(e.target.value.trim());
  };

  useEffect(() => {
    localStorage.setItem("customerDetails", JSON.stringify(customerDetails));

    localStorage.setItem("vinDetails", JSON.stringify(decodedDetails));
  }, [decodedDetails, customerDetails]);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      if (vinNumber.length !== 17) {
        setVinNumberError(true);
        setVinNumberError2(false);
        return;
      }
      if (!/^[A-Z0-9]+$/.test(vinNumber)) {
        setVinNumberError(false);
        setVinNumberError2(true);
        return;
      }

      const url = `https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/${vinNumber}?format=json`;
      const response = await axios.get(url);

      if (response.status === 200) {
        const results = response.data.Results;

        if (results && results.length > 0) {
          const details = {};

          const handlers = {
            Make: (value) => (details["make"] = value ?? ""),
            Model: (value) => (details["model"] = value ?? ""),
            "Engine Model": (value) => (details["engineModel"] = value ?? ""),
            "Drive Type": (value) => (details["driveType"] = value ?? ""),
            "Manufacturer Name": (value) =>
              (details["manufacturerName"] = value ?? ""),
            "Transmission Style": (value) =>
              (details["transmissionStyle"] = value ?? ""),
            "Number of Seat Rows": (value) =>
              (details["numberOfSeatRows"] = value ?? ""),
            "Number of Seats": (value) =>
              (details["numberOfSeats"] = value ?? ""),
            Doors: (value) => (details["doors"] = value ?? ""),
            "Vehicle Type": (value) => (details["vehicleType"] = value ?? ""),
            "Body Class": (value) => (details["bodyClass"] = value ?? ""),
            "Displacement (CC)": (value) =>
              (details["displacementCC"] = value ? value : ""),
            "Displacement (L)": (value) =>
              (details["displacementL"] = value ? value : ""),
            "Engine Number of Cylinders": (value) =>
              (details["engineNumberOfCylinders"] = value ? (
                value
              ) : (
                <EngineSizeDecoder code={vinNumber[4]} />
              )),
            "Plant Country": (value) =>
              (details["plantCountry"] = value ? (
                value
              ) : (
                <CountryDecoder continentCode={vinNumber[0]} />
              )),
            "Model Year": (value) =>
              (details["modelYear"] = value ? (
                value
              ) : (
                <ModelYearDecoder modelYearCode={vinNumber[9]} />
              )),
            "Error Code": (value) => {
              if (value !== "0") {
                throw new Error(`Error decoding VIN: Error Code ${value}`);
              }
            },
          };

          results.forEach((result) => {
            const name = result.Variable;
            const value = result.Value;

            if (name in handlers) {
              handlers[name](value);
            }
          });

          setDecodedDetails(details);
          setVinNumberError(false);
          setVinNumberError2(false);

          const vinNumberDetail = {
            vinNumber: vinNumber,
          };
          localStorage.setItem(
            "vinNumberDetail",
            JSON.stringify(vinNumberDetail)
          );
          navigate(`/vehicle/${code}/PolicyDetails`);
        } else {
          throw new Error("No results or empty results list");
        }
      } else {
        throw new Error(
          `Failed to fetch data from VIN decoder API. Status code: ${response.status}`
        );
      }
    } catch (error) {
      console.error(error.message);
      navigate(`/vehicle/${code}/PolicyDetails`);
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = async () => {
    navigate(`/vehicle/${code}/PolicyDetails`);
  };

  return (
    <>
      {loading ? (
        <div className="jsonForm-loader">
          <Oval
            visible={true}
            height="40"
            width="40"
            color="#4fa94d"
            ariaLabel="oval-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="Vin">
          <div className="VinText">
            <h6>Vehicle Identification Number (VIN)</h6>
            <p>
              Inspect these locations for the VIN, which contains 17 characters
            </p>
          </div>
          <div className="VinImage">
            <img src={vin} alt="VinImage" className="VinImg" />
          </div>
          <div>
            <TextField
              id="outlined-basic"
              label="Enter VIN"
              variant="outlined"
              value={vinNumber}
              onChange={handleVinNumber}
              fullWidth
            />
            {vinNumberError && (
              <div className="vinError">
                The VIN number should be 17 characters.
              </div>
            )}
            {vinNumberError2 && (
              <div className="vinError">
                Invalid VIN number. Must be a mixture of uppercase letters and
                numbers.
              </div>
            )}
          </div>
          <div className="skipBtn">
            <button className="" onClick={handleSkip}>
              <FormattedMessage id="component.button.skip" />
            </button>
          </div>
          <div className="button">
            <button className="vinBtn" onClick={handleSubmit}>
              <FormattedMessage id="component.button.next" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default VinDecoder;
